@import './../../variables.scss';
.contact-us__page {
    .form-box {
        margin: 0 auto;
    }
}

.contact-us {
    padding: 2rem 0;

    @media (min-width: $mq-tablet) {
        padding: 2rem;
    }

    h3 {
        margin-bottom: 1rem;
    }

    .link {
        margin-bottom: 2rem;
        position: relative;
        width: fit-content;

        a {
            align-items: center;
            display: flex;
            font-size: 1.4rem;

            &:hover,
            &:focus,
            &:active {
                outline: 0;
            
                   &+.line {
                     width: 100%;
                }
            }
        }

        .line {
            width: 0%;
            height: 1px;
            background-color: $black;
            transition: width .3s;
            position: absolute;
            top: auto;
            bottom: -2px;
            left: 0%;
            right: 0%;
        }
    }

    &__text {
        align-items: flex-start;
        flex-direction: column;
        padding-right: 2rem;
    }

    &__img {
        height: fit-content;
        flex-direction: column;
        margin: auto 0;

        img {
            align-self: center;
            height: auto;
            width: 100%;
        }
    }

    &__socials {
        display: flex;
        
        svg {
            margin-right: 0.5rem;
        }
    }

    &__ctas {
        justify-content: space-between;

        button {
            margin-bottom: 1rem;
            @media (min-width: $mq-tablet) {
                margin-bottom: 0;
            }
        }
    }

    &__phone{
        display: flex;

        svg {
           padding-right: 4px;
        }
        
        .link {
            display: flex;
            margin-right: 8px;
        }

        img {
            height: 20px;
            padding-right: 8px;
        }
    }
}