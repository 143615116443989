@import '../../variables.scss';

.form-container {
    align-items: flex-start;

    .form-box {
        align-items: flex-start;
        background-color: $white;
        border: 1px solid $black;
        flex-direction: column;
        margin: 2rem auto;
        padding: 1rem;

        @media (min-width: $mq-tablet) {
            padding: 2rem;
        }
        
        h3 {
            margin: 0 auto 1rem;
            text-align: center;

            @media (min-width: $mq-tablet) {
                margin-bottom: 2rem;
            }
        }
    }

    .form-image {
        height: 100%;
        width: 100.4%;
        margin: 0 0 -1px -1px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        @media (min-width: $mq-tablet) {
            //height: 710px;
            margin: -1px 0;
            width: 50%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}