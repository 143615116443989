@import './../../variables.scss';

.hero {
    //background-image: url('./../../static/images/home/hero.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    align-items: center;
    flex-direction: row;

    .container {
        position: relative;
        width: 100%;
    }

    &__background {
        background-color: #222021;
        height: 400px;
        opacity: 0.4;
        position: absolute;
        width: 100%;
    }

    h1 {
        color: $white;
        margin: 0;
    }
}