@import './../../variables.scss';

.blog-card-col {
    align-items: flex-start;

    .image {
        height: 350px;
        margin-bottom: 1rem;
        object-fit: cover;
        width: 100%;
    }

    .date {
        color: $darkGrey;
        font-size: 12px;
        margin-bottom: 1rem;
    }

    h3 {
        font-size: 1.75rem;
        margin-bottom: 0.5rem;
        text-transform: none;
        @media (max-width: 940px) {
            font-size: 1.5rem;
        }
    }

    a {
        &:hover {
            text-decoration: underline; 
            transition: text-decoration 0.3s ease;
        }
    }

    p {
        color: $darkGrey;
        font-size: 1rem;
    }

    .blog-card-caption {
        display: none;
        margin-bottom: 0;
        @media (max-width: 940px) {
            display: block;
        }
    }
}