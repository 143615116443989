.blog {
    .blog-col-space {
        margin-right: 1.5rem;
        @media (max-width: 940px) {
            margin-right: 0;
        }
    }
    .blog-header2 {
        margin: 30px 0;
        @media (max-width: 940px) {
            margin-top: 20px;
        }
    }
}