@import "./../../variables.scss";

.blog-card-row {
    align-items: flex-start;
    margin-bottom: 1.5rem;

    .image {
        height: 200px;
        object-fit: cover;
        width: 50%;
        @media (max-width: 940px) {
            height: 350px;
            margin-bottom: 1rem;
            width: 100%;
        }
    }

    .text {
        align-items: flex-start;
        justify-content: center;

        .date {
            color: $darkGrey;
            font-size: 12px;
            margin-bottom: 0.5rem;
            margin-left: 1.5rem;
            @media (max-width: 940px) {
                margin-left: 0;
            }
        }

        h3 {
            font-size: 1.75rem;
            margin-bottom: 0.5rem;
            margin-left: 1.5rem;
            text-transform: none;
            @media (max-width: 940px) {
                font-size: 1.5rem;
                margin-left: 0;
            }
        }

        a {
            &:hover {
                text-decoration: underline; 
                transition: text-decoration 0.3s ease;
            }
        }

        p {
            color: $darkGrey;
            font-size: 1rem;
            margin-bottom: 0;
            margin-left: 1.5rem;
            @media (max-width: 940px) {
                margin-left: 0;
            }
        }
    }

    .blog-card-caption {
        display: none;
        margin-bottom: 0;
        @media (max-width: 940px) {
            display: block;
        }
    }
}
