@import './../../../variables.scss';

.blog-post {
    .tag {
        background-color: $black;
        border-radius: 20px;
        color: $white;
        margin-right: 2rem;
        padding: 5px 10px;
        width: fit-content;
    }

    &-header {
        align-items: center;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    img {
        margin-bottom: 2rem;
        max-height: 400px;
        object-fit: cover;
        width: 100%;

        @media (min-width: $mq-tablet) {
            margin-bottom: 3rem;
            margin-top: 2rem;
        }
    }

    section {
        margin-bottom: 2rem;
    }

    h2 {
        font-size: 1.25rem;
        margin-bottom: 1rem;
        text-transform: none;
        
        @media (min-width: $mq-tablet) {
            font-size: 1.75rem;
            margin-bottom: 2rem;
        }
    }

    h3 {
        font-size: 1rem;
        margin-bottom: 1rem;
        text-transform: none;
        
        @media (min-width: $mq-tablet) {
            font-size: 1.5rem;
            margin-bottom: 2rem;
        }
    }

    p {
        margin-bottom: 1rem;
    }

    ol, ul {
        margin-bottom: 1rem;
        margin-left: 1rem;

        @media (min-width: $mq-tablet) {
            margin-bottom: 2rem;
        }

        li {
            font-size: 1rem;
            margin-bottom: 0.5rem;

            &::marker {
                margin-right: 0.5rem;
            }
            
            @media (min-width: $mq-tablet) {
                font-size: 1.2rem;
            }
        }
    }

    ol {
        list-style: auto;
    }

    ul {
        list-style: unset;
    }

    a {
        font-weight: 600;

        &:hover {
            text-decoration: underline;
        }
    }

    &-stamps {
        align-items: center;
        display: flex;

        justify-content: center;

        p {
            font-size: $font-base;
        }
    }
}

.blog-col-space {
    margin-right: 1.5rem;

    @media (max-width: 940px) {
        margin-right: 0;
    }
}

.related-articles {
    margin: 2rem 0;

    @media (min-width: $mq-tablet) {
        margin: 4rem 0 2rem;
    }
}