@import './../../variables.scss';

.language-selector {
    margin-left: 7%;

    &__btn {
        display: flex;
        align-items: center;
        text-transform: uppercase;

        svg {
            margin-right: 5px;
        }
    }

    ul {
        &.open {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem;
        background-color: $white;
        box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
                    
            li {
                margin-bottom: 1rem;
                position: relative;
            }
                    
            li:last-child {
                margin-bottom: 0;
            }
        }
                    
        &.closed {
            display: none;
        }
    }
}