@import './src/variables.scss';

.container--accordion {
    @media (min-width: $mq-tablet) {
        width: calc(((100vw - 312px) / 12) * 8 + (24px * 7));   
    }

    h2 {
        margin: 2.5rem 0;
    }
}

.accordion {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 2rem;

    @media (min-width: $mq-tablet) {
        margin-bottom: 4rem;
    }

    &__item {
        border-top: 1px solid $black;
        position: relative;
        width: 100%;
        
        &:last-child {
            border-bottom: 1px solid $black;
        }
        
        &.open {
            background-color: $grey;
        }
    }

    &__btn {
        align-items: center;
        background-color: transparent;
        border: none;
        color: $black;
        cursor: pointer;
        display: flex;
        font-family: inherit;
        font-size: 1.4rem;
        padding: 1rem 0;
        position: relative;
        text-align: left;
        width: 100%;

        @media (min-width: $mq-tablet) {
            font-size: 1.7rem;
        }

        &:after {
            border-left: 1.5px solid $black;
            border-top: 1.5px solid $black;
            content: '';
            display: flex;
            height: 10px;
            margin-bottom: 0.5rem;
            position: absolute;
            right: 4px;
            transform: rotate(225deg);
            width: 10px;
            -webkit-transition: -webkit-transform .3s ease-in-out;
            -ms-transition: -ms-transform .3s ease-in-out;
            transition: transform .3s ease-in-out;
        }

        &:hover,
        &:focus {
            background-color: $grey;
        }

        &--open {
            &:after {
                margin-top: 12px;
                transform: rotate(45deg);
            }
        }

        span {
            width: 90%;
        }
    }

    &__text {
        animation: slidein .3s ease-in-out;
        @keyframes slidein {
            from {
                opacity: 0;
            }
        
            to {
                opacity: 1;
            }
        }
    }
}