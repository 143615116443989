@import './variables.scss';
body {
    margin: 0;
    font-family: 'Lato', 'Roboto', sans-serif;
}

#root {
    position: relative;
}

h1,
h2,
h3,
h4 {
    line-height: 1.1;
    text-transform: uppercase;
}
h1, h2, h3, h4, p {
    
    margin-bottom: 2rem;
}

h1 {
    font-size: $h3;
    @media (min-width: $mq-mobile) {
        font-size: $h2;
    }
    @media (min-width: $mq-tablet) {
        font-size: $h1;
    }
}

h2 {
    font-size: $h4;
    @media (min-width: $mq-mobile) {
        font-size: $h3;
    }
    @media (min-width: $mq-tablet) {
        font-size: $h2;
    }
}

h3 {
    font-size: $h5;
    @media (min-width: $mq-mobile) {
        font-size: $h4;
    }
    @media (min-width: $mq-tablet) {
        font-size: $h3;
    }
}

p {
    font-size: $font-base;
    line-height: 1.3;

    @media (min-width: $mq-tablet) {
        font-size: 1.2rem;
    }
}

a,
button {
    color: $black;
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
        outline: 0;

        &+.line {
            width: 100%;
        }
    }
}

br {
    margin: 10px 0;
    display: block;
    content: '';
}

.align-center {
    text-align: center;
}

.flex {
    display: flex;
    flex-direction: column;

    @media (min-width: $mq-tablet) {
        flex-direction: row;
        align-items: center;
    }

    &--reverse {
        @media (min-width: $mq-tablet) {
            flex-direction: row-reverse;
        }
    }

    &--column {
        flex-direction: column;
    }

    &__col {

        @media (min-width: $mq-tablet) {
            &--3 {
                max-width: 25%;
                width: 25%;
            }

            &--4 {
                max-width: 33%;
                width: 33%;
            }

            &--5 {
                max-width: 40%;
                width: 40%;
            }

            &--6 {
                max-width: 50%;
                width: 50%;
            }

            &--7 {
                max-width: 60%;
                width: 60%;
            }

            &--8 {
                max-width: 67%;
                width: 67%;
            }

            &--9 {
                max-width: 75%;
                width: 75%;
            }
        }
    }
}