@import './../../variables.scss';

.container {
    margin: 2rem 1rem;
    padding: 0 0.3rem;
    max-width: 1200px;

    @media (min-width: $mq-tablet) {
        margin: 2rem auto;
        padding: 0 2rem;
    }

    &--header { 
        background-color: white;
        box-shadow: 0 0 12px #ddd;
        margin: 0 auto;
        position: sticky;
        top: 0;
        z-index: 1;

        @media (min-width: $mq-tablet) {
            margin: 0;
            max-width: 100%;
            padding: 0;
            width: 100%;
        }
    }
}

.header {
    align-items: center;
    flex-direction: row;
    margin: 0;
    padding: 1rem;
    
    @media (min-width: $mq-tablet) {
        padding: 1rem 2rem;
        margin: 0 auto;
        max-width: 1200px;
    }
    
    .logo {
        font-size: 0;
        font-weight: 800;
        height: auto;
        background-image: url('./../../static/images/logo/logo.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        color: transparent;
        height: 41px;
        width: 200px;

        @media (min-width: $mq-tablet) {
            width: 100px;
        }

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
            color: transparent;
        }
    }

    nav {
        margin: 0 0 0 auto;
        width: 100%;

        ul {
            justify-content: space-between;
            width: 100%;

            @media (max-width: 940px) {
                &.menu-open {
                    display: flex;
                    position: absolute;
                    margin-top: 2rem;
                    background-color: $white;
                    width: -webkit-fill-available;
                    left: 0;
                    padding: 0 1rem;

                    .line {
                        display: none;
                    }

                    ul {
                        &.open {
                            position: inherit;
                            box-shadow: none;
                            width: auto;
                            padding: 0;

                            li {
                                border: none;
                                padding: 0 1rem 1rem;
                                margin: 0;

                                &:first-child {
                                    padding-top: 1rem;
                                }

                                &:last-child {
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }

                    li {
                        border-bottom: 1px solid $black;
                        padding: 1rem 0;
                    }
                }
                
                &.menu-closed {
                    display: none;
                }
            }

            @media (min-width: $mq-tablet) {
                display: flex;
                align-items: center; 
            }

            &.open {
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 1rem;
                background-color: $white;
                box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
                width: 200px;

                li {
                    margin-bottom: 1rem;
                }

                li:last-child {
                    margin-bottom: 0;
                }
            }

            &.closed {
                display: none;
            }

            li {
                position: relative;
            }
        }
    }

    button {
        border: none;
        background-color: transparent;
        font-size: inherit;
        padding: 0;
        position: relative;
    
        .chevron {
            position: absolute;
            display: block;
            right: -10%;
            top: 80%;
    
                &::before,
                &::after {
                    position: absolute;
                    top: -8px;
                    display: block;
                    content: "";
                    border: 8px solid transparent;
                }
    
                &::before {
                    border-top-color: $black;
                    height: auto;
                }
    
                &::after {
                    top: -10px;
                    border-top-color: $white;
                }
            }
        }
    
    .line {
        width: 0%;
        height: 1px;
        background-color: $black;
        transition: width .3s;
        position: absolute;
        top: auto;
        bottom: -2px;
        left: 0%;
        right: 0%;
    }

    .burger-menu {
        margin-left: 5%;
        display: block;

        @media (min-width: $mq-tablet) {
            display: none;
        }

        &.open {
            span {
                opacity: 1;
                transform: rotate(45deg) translate(-6px, -14px);
                background: $black;

                &:nth-last-child(3) {
                    opacity: 0;
                    transform: rotate(0deg) scale(0.2, 0.2);
                }
                
                &:nth-last-child(2) {
                    transform: rotate(-45deg) translate(0, 10px);
                }
            }
        }
        
        span {
            display: block;
            width: 25px;
            height: 2px;
            margin-bottom: 5px;
            position: relative;
            background: $black;
            border-radius: 3px;
            z-index: 1;
            transform-origin: 4px 0px;
            transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
                background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
                opacity 0.55s ease;
                
            &:first-child {
                transform-origin: 0% 0%;
            }
    
            &:nth-last-child(2) {
                transform-origin: 0% 100%;
            }
        }
    }
}