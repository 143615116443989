@import "./src/variables.scss";

.form-container {
    p {
        line-height: 1.2;
        text-align: center;
        padding: 0;

        @media (min-width: $mq-mobile) {
            padding: 0 3rem;
        }
    }

    .form {
        display: flex;
        flex-direction: column;
        width: -webkit-fill-available;

        .error {
            color: red;
            font-size: 1rem;
            margin: 0 0 1rem 0;
            padding: 0;
            text-align: left;
        }

        &__items-group {
            display: flex;
            flex-direction: column;

            @media (min-width: $mq-tablet) {
                flex-direction: row;
                justify-content: space-between;
            }

            .form__item {
                width: 100%;

                @media (min-width: $mq-tablet) {
                    max-width: 48%;
                    width: 48%;
                }
            }
        }

        &__item {
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;
            position: relative;

            fieldset {
                display: flex;
                flex-direction: column;

                legend {
                    margin-bottom: 1rem;
                }

                div {
                    align-items: center;
                    display: flex;
                    margin-bottom: 1rem;

                    p {
                        margin-bottom: 0;
                        padding: 0 0  0 0.5rem;
                    }

                    &:last-child {
                        label {
                            margin-right: 1rem;
                        }
                        input[type="text"] {
                            width: 100%;
                        }
                    }
                }
            }

            label {
                margin-bottom: 0.25rem;
            }

            input,
            select,
            textarea {
                border: 1px solid $black;
                font-size: 1rem;
                padding: 0.7rem;
            }

            select {
                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
            }

            span {
                border-left: 1.5px solid #000000;
                border-top: 1.5px solid #000000;
                content: "";
                display: flex;
                height: 10px;
                margin-bottom: 0.5rem;
                position: absolute;
                right: 14px;
                top: 32px;
                transform: rotate(225deg);
                width: 10px;
                transition: transform 0.3s ease-in-out;
            }

            textarea {
                font-family: 'Lato', 'Roboto', sans-serif;
            }

            .PhoneInput {
                margin-left: 2px;
            }
        }

        &__confirmation {
            margin-bottom: 1rem;

            a {
                display: inline-block;
                font-weight: bold;
                margin-left: 2px;

                &:hover,
                &:active,
                &:focus {
                    text-decoration: underline;
                }
            }
        }
    }

    &__thank-you,
    &__error {
        margin: 4rem 0;
        text-align: center;
    }
}

.lds-ring {
    display: inline-block;
    height: 42px;
    position: relative;
    width: 42px;

    div {
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border: 4px solid $black;
        border-color: $black transparent transparent transparent;
        border-radius: 50%;
        box-sizing: border-box;
        display: block;
        height: 36px;
        margin: 4px;
        position: absolute;
        width: 36px;
    }

    div:nth-child(1) {
        animation-delay: -0.45s;
    }

    div:nth-child(2) {
        animation-delay: -0.3s;
    }

    div:nth-child(3) {
        animation-delay: -0.15s;
    }
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}