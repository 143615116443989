@import '../../variables.scss';

.highlights {
    &.flex {
        align-items: flex-start;
    }
    
    .flex {
        align-items: center;
        flex-direction: column;
        margin-bottom: 1.5rem;
        text-align: center;

        &:last-child {
            margin: 0
        }

        @media (min-width: $mq-tablet) {
            margin: 0 2rem 0 0;
        }

        img {
            height: auto;
            margin-bottom: 10px;
            width: 100px;

            @media (min-width: $mq-tablet) {
                width: 130px;
            }
        }

        h3 {
            margin-bottom: 10px;
        }
    }
}