@import '../../variables.scss';

.testimonials {
    margin-top: 2rem;

    &.testimonials {
        align-items: flex-start;
    }

    .testimonial {
        flex-direction: column;
        padding: 0 0 1rem;

        @media (min-width: $mq-tablet) {
            padding: 0 2rem 0;
        }
        
        &:last-child {
            padding: 0;

            @media (min-width: $mq-tablet) {
                padding-right: 0;
                    padding-left: 2rem;
            }
        }

        &__img {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        img {
            border-radius: 50%;
            max-width: 250px;
            width: 100%;
        }

        &__author {
            font-weight: 600;
            margin: 1rem 0 0;
        }

        &__role {
            font-style: italic;
        }

        &__text {
            display: flex;
        }
    }
}