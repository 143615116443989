@import '../../variables.scss';

.cta-block {
    h2 {
        margin: 0 2rem 1rem 0;
        
        @media (min-width: $mq-tablet) {
            margin: 0 2rem 0 0;
        }
    }

    p {
        margin-top: 0;
        @media (min-width: $mq-tablet) {
            margin-top: 2rem;
        }
    }

    &__text {
        flex-direction: column;
        align-items: flex-start;
    }
}