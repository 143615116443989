//Media queries
$mq-desktop: 1200px;
$mq-tablet: 941px;
$mq-tablet-s: 768px;
$mq-mobile: 564px;

//Font sizes
$font-base: 1rem;
$h1: 3rem;
$h2: 2.5rem;
$h3: 2rem;
$h4: 1.5rem;
$h5: 1.2rem;

//Colours
$black: #000000;
$white: #ffffff;
$grey: #f0f0f0;
$darkGrey: #787878;