@import '../../variables.scss';

.carousel {
    background-color: $grey;

    .container {
        padding: 2rem 1rem;

        @media (min-width: $mq-tablet) {
            padding: 2rem;
        }

        h3 {
            margin-bottom: 2rem;
        }
    }

    &__slide {
        display: flex;
        flex-direction: column;
        //width: 80%;
        align-items: center;
        margin: 0 auto;

        &-img {
            align-items: center;
            background-color: $white;
            border-radius: 50%;
            display: flex;
            height: 150px;
            justify-content: center;
            margin-bottom: 2rem;
            width: 150px;
        }

        img {
            margin-bottom: 1rem;
            width: 100%;
            height: 250px;
            object-fit: cover;

            @media (min-width: $mq-mobile) {
                height: 350px;
            }

            @media (min-width: $mq-tablet-s) {
                height: 450px;
            }
        }

        a {
            display: flex;
            align-self: flex-start;
            font-weight: bold;
            text-decoration: underline;
        }

        &--review {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 2rem;

            img {
                height: 80px;
                width: auto;
            }
        }
    }

    &__text-review {
        font-style: italic;
    }

    &__author {
        align-self: flex-end;
        font-weight: bold;
    }

    .slick-slider {
        margin: 0 auto 1.5rem;
        position: relative;
        width: 80%;
    }

    .slick-prev {
        left: -38px;

        @media (min-width: $mq-tablet-s) {
            left: -75px;
        }

        &::before {
            content: url("../../static/svg/arrow-left.svg");
        }
    }

    .slick-next {
        right: -24px;

        @media (min-width: $mq-tablet-s) {
            right: -75px;
        }

        &::before {
            content: url("../../static/svg/arrow-right.svg");
        }
    }

    .slick-dots {
        bottom: -30px;
        list-style-type: none;

        @media (min-width: $mq-tablet-s) {
            bottom: -25px;
        }

        li {
            &.slick-active {
                button::before {
                    background-color: $black;
                }
            }

            button {
                opacity: 1;

                &::before {
                    font-family: none;
                    font-size: unset;
                    opacity: 1;
                    width: 10px;
                    height: 10px;
                    border: 1px solid $black;
                    background-color: $white;
                    border-radius: 50%;
                }
            }
        }
    }

    .cta {
        margin-top: 2rem;
        display: block;
    }
}