@import '../../variables.scss';
.background {
    background-color: $grey;

    .grid {
        padding: 2rem 0;
    }
}

.grid {
    &-img {
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 1rem;

        @media (min-width: $mq-tablet) {
            margin-bottom: 0;
        }

        img {
            width: 100%;
            height: auto;
        }

        .link-wrapper {
            margin-top: 0.5rem;
            position: relative;
        }

        a {
            font-weight: 600;
            
            &:hover, &:focus, &:active {
                outline: 0;

                &+.line {
                    width: 100%;
                }
            }
        }
        
        .line {
            width: 0%;
            height: 1px;
            background-color: $black;
            transition: width .3s;
            position: absolute;
            top: auto;
            bottom: -2px;
            left: 0%;
            right: 0%;
        }
    }

    &-text {
        align-items: flex-start;
        flex-direction: column;
        padding: 1rem 0 2rem;

        @media (min-width: $mq-tablet) {
            padding: 1rem 0;
        }

        div {
            margin-left: 0;
            
            @media (min-width: $mq-tablet) {
                margin-left: 2rem;
            }
        }

        .handwriting {
            p, span {
                font-family: "Caveat", cursive;
            }

            p {
                font-size: 2.2rem;
                text-align: center;
            }

            span {
                display: block;
                font-size: 1.2rem;
                text-align: right;
            }
        }
    }

    &.flex--reverse {
        .grid-text {
            div {
                @media (min-width: $mq-tablet) {
                    margin-left: 0;
                    margin-right: 2rem;
                } 
            }
        }
        
    }

    .grid-text {
        div {
            @media (min-width: $mq-tablet) {
                margin-left: 2rem;
                margin-right: 0;
            }
        }
    }
}