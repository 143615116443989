@import '../../variables.scss';

.footer {
    background-color: $black;
    color: $white;
    padding: 2rem 0 1rem;

    p {
        margin-bottom: 2rem;
    }

    .container {
        margin: 0 1rem;

        @media (min-width: $mq-tablet) {
            margin: 0 auto;
        }

        a {
            align-items: center;
            color: inherit;
            display: flex;
            text-align: center;
        }
    }

    &__slogan {
        font-size: 1rem;
        text-align: center;
       
        @media (min-width: $mq-tablet) {
            font-size: 1.2rem;
        }
    }

    &__contacts {
        justify-content: space-between;

        & > div,
        & > a {
            margin-bottom: 2rem;
        }
    }

    &__whatsapp {
        display: flex;
        flex-direction: row;
        
        a {
            align-items: center;
            fill: $white;
            padding-right: 8px;

            svg {
                margin-right: 0.5rem;
            }
        }
    }
    
    &__social {
        display: flex;

        .instagram,
        .facebook {
            fill: $white;
            margin-right: 1rem;
        }
    }

    img {
        margin-right: 10px;
        width: 25px;
    }

    .language-selector {
        margin-left: 0;
        padding-left: 0;
        position: relative;

        &__btn {
            background-color: $black;
            border: 2px solid $white;
            color: $white;
            padding: 11px;

            svg {
                & > path {
                    stroke: $white;
                }
            }
        }

        ul {
            top: -109px;
            left: 0;
            padding: 11px 16px;
            background-color: black;
            color: white;
            border: 2px solid white;

            button {
                background-color: transparent;
                border: none;
                color: white;
            }
        }
    }

    &__legal {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto 1rem;

        .links {
            display: flex;
        }

        p, a {
           font-size: 0.8rem; 
        }

        p {
            margin: 0 0 0.5rem 0;
        }

        a {
            display: inline-flex;
            margin: 0 1rem 0.5rem 0;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__author {
        display: flex;
        font-size: 10px;
        justify-content: center;
        opacity: 50%;
    }
}