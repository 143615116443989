@import '../../variables.scss';

.cta {
    border: 2px solid $white;
    background-color: $black;
    color: $white;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    padding: 10px 15px;
    width: fit-content;
    transition: .3s;

    @media (min-width: $mq-tablet) {
        font-size: 1.5rem;
    }

    &:hover,
    &:focus,
    &:active {
        border-color: $black;
        background-color: $white;
        color: $black;

        svg {
            path {
               fill: $black; 
            }
        }
    }

    svg {
        margin-left: 15px;
    }
}